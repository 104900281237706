import React from 'react';
import FormOne from '../contact/FormOne';
import Accordion from 'react-bootstrap/Accordion';
import SectionTitle from '../../elements/section-title/SectionTitle';
import { FaHandHoldingUsd, FaToolbox, FaStar } from 'react-icons/fa';


const AboutOne = () => {
    return (
        <div className="section-padding mt-4">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="why-choose-us">
                            <SectionTitle
                                subtitle="About Us"
                                title="Our Ecosystem"
                                description="At P3 Labs, we believe in building a comprehensive ecosystem that caters to all your financial needs. Explore our diverse range of products, each designed to provide unique benefits and opportunities."
                                textAlignment="heading-left"
                                textColor=""
                            />
                            <Accordion defaultActiveKey="1">
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header><FaHandHoldingUsd />Pluto</Accordion.Header>
                                    <Accordion.Body>
                                        Pluto simplifies on-chain DeFi by offering a user-friendly "set it and forget it" approach, allowing users to effortlessly deposit Sol into the Pluto Application and earn APY on their assets without active management, compounding their deposits until withdrawal.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2">
                                    <Accordion.Header><FaToolbox />Airdrop / Snapshot</Accordion.Header>
                                    <Accordion.Body>
                                        Our Airdrop Platform offers hassle-free control without subscriptions or commitments, eliminating upfront, yearly, and monthly fees; instead, fees are transaction-based for fairness and transparency.
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="3">
                                    <Accordion.Header><FaStar />Discord Royalty Guard</Accordion.Header>
                                    <Accordion.Body>
                                        Experience personalized NFT authentication and protection like never before with Discord Royalty Guard, offering customizable settings and personalized roles in Discord for creators and collectors.
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                    </div>
                    <div className="col-xl-5 col-lg-6 offset-xl-1">
                        <div className="why-choose-us mb--30">
                            <div className="why-choose-thumb">
                                <img src={process.env.PUBLIC_URL + "/images/ecosystem.svg"} alt="P3 Labs Ecosystem" style={{ "height": "100%", "width": "100%" }} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutOne;