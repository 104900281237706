import React from 'react';
import SectionTitle from '../../elements/section-title/SectionTitle';

const BlogOne = () => {
    return (
        <div className="section section-padding-equal">
            <div className="container">
                <SectionTitle 
                    subtitle="Social Media"
                    title="Latest updates"
                    textAlignment=""
                    textColor=""
                />
                <div className='row text-center justify-content-center'>
                    <div className='col-lg-auto'>
                        <div className="">
                            <a className="" data-tweet-limit="3" href="https://twitter.com/p3labs_xyz" target="_blank" rel="noreferrer">Twitter</a>
                        </div>
                    </div>
                </div>
            </div>
            <ul className="shape-group-1 list-unstyled">
                <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/bubble-1.png"} alt="bubble" /></li>
                <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/line-1.png"} alt="bubble" /></li>
                <li className="shape shape-3"><img src={process.env.PUBLIC_URL + "/images/others/line-2.png"} alt="bubble" /></li>
                <li className="shape shape-4"><img src={process.env.PUBLIC_URL + "/images/others/bubble-2.png"} alt="bubble" /></li>
            </ul>
        </div>
    )
}

export default BlogOne;