import React from 'react';
import './nav.css'

const Nav = () => {
    return (
        <nav className="mainmenu-nav">
            <ul className="mainmenu">
                <li className="menu-item-has-children">
                </li>
                <li>
                    {/* <Link to="/collection">Collection</Link> */}
                </li>
            </ul>
        </nav>
    )
}

export default Nav;