import React from 'react';
import {  FaTwitter } from "react-icons/fa";

const Data = [
    {
        image: "/images/icons/sskp.png",
        name : 'SSKP',
        position: 'Co Founder',
        twitter_username: 'SouthShoreKP'
    },
    {
        image: "/images/icons/ribo.png",
        name : 'Ribo',
        position: 'Co Founder',
        twitter_username: 'ribo_sol'
    },
    {
        image: "/images/icons/anti.png",
        name : 'Anti',
        position: 'Co Founder',
        twitter_username: 'antiisaint'
    },
    {
        image: "/images/icons/mortiz.png",
        name : 'Moritz',
        position: 'Manager',
        twitter_username: 'solaprometheus'
    }
]

const twitterUrl = "https://twitter.com/";

const BrandItem = () => {
    return (
        <>
            {Data.map((data, index) => (
                <div className="col-lg-3 col-6" key={index}>
                    <div className="brand-grid text-center">
                        <img src={data.image} alt="Brand" style={{height: '60%', width: '60%'}} className="p-2"/>
                        <br></br>
                        <a className='fs-3 fw-bold'>{data.name}</a>
                        <br></br>
                        <a className='fw-light text-muted'>{data.position}</a>
                        <br></br>
                        <a href={twitterUrl + data.twitter_username} target="_blank" rel="noreferrer"><FaTwitter /></a>
                    </div>
                </div>
            ))}
        </>
    )
}

export default BrandItem;