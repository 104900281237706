import React from 'react';
import { Link } from 'react-router-dom';
import Offcanvas from 'react-bootstrap/Offcanvas'
import { FaDiscord, FaTwitter, FaGithub, FaMoneyBill, FaChalkboard, FaRocket } from "react-icons/fa";


const OffcanvasMenu = ({offcanvasShow, offcanvasHide}) => {
    return (
        <Offcanvas show={offcanvasShow} onHide={offcanvasHide} placement="end" className="header-offcanvasmenu">
            <Offcanvas.Header closeButton></Offcanvas.Header>
            <Offcanvas.Body>
                <div className="row ">
                    <div className="col-lg-5 col-xl-6">
                        <ul className="main-navigation list-unstyled">
                            <li><Link to="/">Home</Link></li>
                            <li><a href="https://app.p3labs.xyz" target="_blank" rel="noreferrer">App</a></li>
                            <li><a href="https://magiceden.io/marketplace/p3_labs_market_makers?activeTab=items" target="_blank" rel="noreferrer">Collection</a></li>
                        </ul>
                    </div>
                    <div className="col-lg-7 col-xl-6">
                        <div className="contact-info-wrap">
                            <div className="contact-inner">
                                <address className="address">
                                    <span className="title">App</span>
                                    <a href="https://app.p3labs.xyz" target="_blank" rel="noreferrer"><FaRocket />Launch</a>
                                </address>
                            </div>
                            <div className="contact-inner">
                                <address className="address">
                                    <span className="title">Collections</span>
                                    <a href="https://magiceden.io/marketplace/p3_labs_market_makers?activeTab=items" target="_blank" rel="noreferrer"><FaMoneyBill />P3 Labs: Market Makers</a>
                                </address>
                            </div>
                            <div className="contact-inner">
                                <address className="address">
                                    <span className="title">Ecosystem</span>
                                    <a href="https://p3-labs.gitbook.io/p3labs/" target="_blank" rel="noreferrer"><FaGithub />Whitepaper</a>
                                </address>
                            </div>
                            <div className="contact-inner">
                                <h5 className="title">Social</h5>
                                <div className="contact-social-share">
                                    <ul className="social-share list-unstyled">
                                        <li>
                                            <a href="https://www.twitter.com/p3labs_xyz" target="_blank" rel="noreferrer"><FaTwitter /></a>
                                        </li>
                                        <li>
                                            <a href="https://www.discord.gg/vXNwcbJk73" target="_blank" rel="noreferrer"><FaDiscord /></a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Offcanvas.Body>
        </Offcanvas>
    )
}

export default OffcanvasMenu;
