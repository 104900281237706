import React from 'react';
import { Link } from 'react-router-dom';
import { FaTimes } from "react-icons/fa";
import { FaDiscord, FaTwitter, FaGithub, FaMoneyBill, FaRocket } from "react-icons/fa";

const MobileMenu = ({MobileHandler}) => {

    return (
        <div className="mobilemenu-popup">
            <div className="mobilemenu-inner">
                <div className="mobilemenu-header">
                    <div className="mobile-nav-logo">
                        <Link to="/">
                            <img className="light-mode" src="/images/logo.svg" alt="Site Logo" />
                            <img className="dark-mode" src="/images/logo-white.svg" alt="Site Logo" />
                        </Link>
                    </div>
                    <button className="mobile-menu-close" onClick={MobileHandler} ><FaTimes /></button>
                </div>
                <div className="mobilemenu-body">
                    <div className=" p-3 ml-3">
                        <div className="">
                            <div className="p-2">
                                    <span className="title">App</span>
                                    <br></br>
                                    <a href="https://app.p3labs.xyz" target="_blank" rel="noreferrer"><FaRocket /> Launch</a>
                            </div>
                            <div className="p-2">
                                <span className="mobileTitle">Collection</span>
                                <br></br>
                                <a href="https://magiceden.io/marketplace/p3_labs_market_makers?activeTab=items" target="_blank" rel="noreferrer"><FaMoneyBill /> P3 Labs: Market Makers</a>
                            </div>
                            <div className="p-2">
                                <span className="mobileTitle">Ecosystem</span>
                                <br></br>
                                <a href="https://p3-labs.gitbook.io/p3labs/" target="_blank" rel="noreferrer"><FaGithub /> Whitepaper</a>
                            </div>
                            <div className="">
                                <h5 className="title m-2">Social</h5>
                                <div className="">
                                    <ul className="list-unstyled">
                                        <li className=' d-inline p-2'>
                                            <a href="https://www.twitter.com/p3labs_xyz" target="_blank" rel="noreferrer"><FaTwitter /></a>
                                        </li>
                                        <li className=' d-inline p-2'>
                                            <a href="https://www.discord.gg/vXNwcbJk73" target="_blank" rel="noreferrer"><FaDiscord /></a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MobileMenu;