import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import SEO from '../common/SEO';
import AboutOne from '../component/about/AboutOne';
import BannerOne from '../component/banner/BannerOne';
import BannerTwo from '../component/banner/BannerTwo';
import BlogOne from '../component/blog/BlogOne';
import BrandOne from '../component/brand/BrandOne';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import ProjectFour from '../component/project/ProjectFour';

const Home = () => {
    return (
        <>
            <SEO title="Web 3 Payments" />
            <ColorSwitcher />
            <main className="main-wrapper">
                <HeaderOne />
                <BannerOne />
                {/* <BannerTwo /> */}
                <AboutOne />
                <ProjectFour />
                <BrandOne />
                <BlogOne />
                <FooterOne parentClass="" />
            </main>
        </>
    )
}

export default Home;

