import React from 'react';
import { FaTwitter, FaDiscord, FaGithub } from "react-icons/fa";


const FooterOne = ({parentClass}) => {
    
    return (
        <footer className={`footer-area ${parentClass}`}>
            <div className="container">
                <div className="footer-top">
                    <div className="footer-social-link">
                        <ul className="list-unstyled">
                            <li><a href="https://twitter.com/p3labs_xyz" target="_blank" rel="noreferrer"><FaTwitter /></a></li>
                            <li><a href="https://p3-labs.gitbook.io/p3labs/" target="_blank" rel="noreferrer"><FaGithub /></a></li>
                            <li><a href="https://discord.gg/vXNwcbJk73"target="_blank" rel="noreferrer"><FaDiscord /></a></li>
                        </ul>
                    </div>
                </div>
                <div className="footer-main">
                    <div className="row">
                    </div>
                </div>
                <div className="footer-bottom"> 
                    <div className="row">
                        <div className="col-md-6">
                            <div className="footer-copyright">
                                <span className="copyright-text">© {new Date().getFullYear()}. All rights reserved by South Shore Development</span>
                            </div>
                        </div>
                        <div className="col-md-6">
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default FooterOne;