import React from 'react';
import SectionTitle from '../../elements/section-title/SectionTitle';
import { FaHandHoldingUsd } from 'react-icons/fa';

const ProjectFour = () => {
    return (
        <div className="section section-padding-equal bg-color-white">
            <div className="container">
                <SectionTitle
                    subtitle=""
                    title="Platform Features"
                    description=""
                    textAlignment="heading-dark-left mb--90"
                    textColor=""
                />
                <div className="project-add-banner row">
                    <div className="content col-lg-8">
                        <span className="subtitle">Status: In Development</span>
                        <h3 className="title mb-3">Pluto</h3>
                        <p className='text'>Pluto presents an ideal solution to overcome the existing learning curve in on-chain DeFi. With its straightforward "set it and forget it" approach, users can effortlessly deposit their Sol into the Pluto Application, allowing their assets to be utilized as loans. By doing so, users can passively earn APY on their deposits without the need for any further interaction. It's worth noting that your deposit will continue to compound until you decide to withdraw from our platform.</p>
                        <div className='text-center'>
                            <a href="https://p3-labs.gitbook.io/p3labs/phase-1/p3-labs-pluto" target="_blank" rel="noreferrer" className='axil-btn btn-fill-primary btn-large'>Learn More</a>
                        </div>
                    </div>
                    <div className="thumbnail col-lg-4">
                        <FaHandHoldingUsd style={{ "height": "250px", "width": "250px" }} className='m-2 plutoIcon'></FaHandHoldingUsd>
                    </div>
                </div>
                <div className="project-add-banner">
                    <div className="thumbnail">
                        <img src={process.env.PUBLIC_URL + "/images/wallet.png"} alt="Mockup" style={{ height: '65%', width: '65%' }} />
                    </div>
                    <div className="content">
                        <span className="subtitle">Status: Live</span>
                        <h3 className="title mb-3">Airdrop / Snapshot</h3>
                        <p className='text'>Our Airdrop Platform is a hassle-free and flexible service that doesn't require any subscriptions or commitments from our users. With a transaction-based pricing model, there are no upfront fees, yearly fees, or monthly fees to worry about. Instead, our fees are based on the number of transactions made on the platform, with rates shown on our whitepaper.</p>
                    </div>
                </div>
                <div className="project-add-banner">
                    <div className="content">
                        <span className="subtitle ">Status: Live</span>
                        <h3 className="title mb-3">Discord Royalty Guard</h3>
                        <p className='text'>Our Holders Authenticator is designed to offer a tailored experience for both NFT creators and collectors. By using our platform, you can customize your authentication and protection settings to match your specific needs and preferences. Our application also provides collectors with personalized roles in Discord based on their holdings, making it easier for them to showcase their collections and connect with other collectors. With our personalized approach, you can be confident that you are getting the most customized and effective NFT authentication and protection available.</p>
                    </div>
                    <div className="thumbnail">
                        <img src={process.env.PUBLIC_URL + "/images/discord.png"} alt="Mockup" style={{ 'height': '200px', 'width': '300px' }} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProjectFour;